import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';


function Youtube(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" {...props}>
            <path d="M18.5,37A18.5,18.5,0,0,1,11.3,1.454,18.5,18.5,0,0,1,25.7,35.546,18.383,18.383,0,0,1,18.5,37Zm0-25.283c-.062,0-6.189,0-7.71.423a2.5,2.5,0,0,0-1.744,1.791,27.219,27.219,0,0,0-.412,4.877,27.219,27.219,0,0,0,.412,4.877,2.505,2.505,0,0,0,1.744,1.792c1.521.418,7.649.423,7.71.423s6.188,0,7.709-.423a2.508,2.508,0,0,0,1.745-1.792,27.219,27.219,0,0,0,.412-4.877,27.219,27.219,0,0,0-.412-4.877A2.508,2.508,0,0,0,26.21,12.14C24.688,11.722,18.562,11.717,18.5,11.717ZM16.65,22.2V16.034l4.934,3.083L16.652,22.2Z" transform="translate(0 0)" fill="#fff"/>
        </SvgIcon>
    );

}

export default Youtube;