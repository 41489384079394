import React from 'react';
import { Grid, Link } from '@mui/material';
import Instagram from '../images/instagram';
import Linkedin from '../images/linkedin';
import Youtube from '../images/youtube';


function SocialNetworks(props) {

    const appBarIconLinkedin = {
        cursor: 'pointer',
        fontSize: props.size,
        // '150%',
        'path': {
            fill: props.color,
        },
        '&:hover path': {
            fill: '#FFF7D6',
        },
    };

    const appBarIconInstagram = {
        cursor: 'pointer',
        fontSize: props.size,
        'path': {
            fill: props.color,
        },
        '&:hover path': {
            fill: '#FFF7D6',
        },
    };

    const appBarIconYoutube = {
        cursor: 'pointer',
        fontSize: props.size,
        'path': {
            fill: props.color,
        },
        '&:hover path': {
            fill: '#FFF7D6',
        },
    };

    return (
        <>
            <Grid item>
                <Link href="https://www.linkedin.com/company/dipucrio/?originalSubdomain=br" target="_blank" sx={{ textDecoration: 'none' }}>
                    <Linkedin sx={appBarIconLinkedin} />
                </Link>
            </Grid>
            <Grid item>
                <Link href="https://www.instagram.com/dipucrio/" target="_blank" sx={{ textDecoration: 'none' }}>
                    <Instagram sx={appBarIconInstagram} />
                </Link>
            </Grid>
            <Grid item>
                <Link href="https://www.youtube.com/channel/UCGgHFfFhdad80ODtkYB3Eyw" target="_blank" sx={{ textDecoration: 'none' }}>
                    <Youtube sx={appBarIconYoutube} />
                </Link>
            </Grid>
        </>
    )
}

export default SocialNetworks;