import React from 'react';
import { Grid, Typography, Box, Link } from '@mui/material';
import IcoPlus from '../images/ico-plus.svg';
import Bg from '../images/bg.svg';
import LogoCEIAzul from '../images/logo-CEI-azul.svg';
import LogoPUC from '../images/logo-PUC-Rio.png';
import LogoDI from '../images/logo-DI.svg';
import * as membersData from '../utils/membersData';
import { Link as LinkScroll } from "react-scroll";



const About = (props) => {

    const boxNowRap = {
        whiteSpace: 'nowrap',
        display: 'inline',
    };

    return (
        <Grid container item xl={12} lg={12} md={12} xs={12} sx={{ height: 'auto', justifyContent: 'center', paddingTop: { md: '50px', xs: '30px' }, backgroundImage: `url(${Bg})`, backgroundRepeat: "no-repeat",  backgroundPositionX: {md: "40%", xs: "center"}, backgroundPositionY: "bottom"}}>
            {/* <Grid container item xl={12} lg={12} md={12} xs={12} id="containerSection"> */}
            <Box id="about" sx={{ maxWidth: 'lg', width: '80%', margin: '0 auto' }}>
                <Grid container item xl={12} lg={12} md={12}>
                    <Grid item>
                        <Typography sx={{ color: "#003C6F", fontSize: { md: '40px', xs: '30px' }, paddingBottom: '15px', fontWeight: "bold" }}>
                            Sobre o Conselho
                        </Typography>
                    </Grid>
                    <Grid container item xl={12} lg={12} md={12} flexDirection='row'>
                        <Grid item xl={6} lg={6} md={6} sx={{ paddingRight: { md: '2vw', xs: '0' } }}>
                            <Typography paragraph sx={{ fontSize: { md: '16px', xs: '13px' }, color: '#202020', letterSpacing: '0', textAlign: "left" }}>
                                O Conselho Empresarial para Inovação é um grupo multidisciplinar com o objetivo de agregar conhecimento e experiência para orientar e incentivar a inovação desenvolvida no Departamento de Informática e conectar os discentes e docentes às demandas da sociedade. Buscamos que essas inovações gerem cada vez mais riqueza de forma igualitária, diversa e empática, impactando toda a sociedade.
                            </Typography>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sx={{ paddingLeft: { md: '2vw', xs: '0' } }}>
                            <Typography paragraph sx={{ fontSize: { md: '16px', xs: '13px' }, color: '#202020', letterSpacing: '0', textAlign: "left" }}>
                                A busca por históricos, currículos, jornadas e carreiras propositalmente diversas na composição do conselho nos permite trazer perspectivas e uma sensibilidade em relação ao mundo complementar às já criadas pelo próprio ecossistema da <Box style={boxNowRap}>PUC-Rio</Box>.
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container item display='flex' flexDirection="row" md={8} xs={12} sx={{ alignItems: 'center', justifyContent: 'space-between', marginTop: '40px' }}>
                    <Grid item md={4} xs={6} sx={{ justifyContent: 'center' }}>
                        <LinkScroll smooth spy={true} to="home" style={{ textDecoration: 'none', cursor: 'pointer' }}>
                            <Grid container item sx={{ justifyContent: 'center' }}>
                                <img src={LogoCEIAzul} alt='logo Conselho Empresarial para Inovação do DI' style={{ width: '100%' }} />
                            </Grid>
                        </LinkScroll>

                    </Grid>
                    <Grid item sx={{ display: { md: 'flex', xs: 'none' } }}>
                        <img src={IcoPlus} alt="ícone de soma" style={{ width: '85%' }} />
                    </Grid>
                    <Grid container item md={2} xs={4} sx={{ justifyContent: 'center' }}>
                        <Link href="https://www.puc-rio.br/index.html" target="_blank" sx={{ textDecoration: 'none', width: '100%' }} >
                            <Grid container item sx={{ justifyContent: 'center' }}>
                                <img src={LogoPUC} alt='logo PUC Rio' style={{ width: '80%' }} />
                            </Grid>
                        </Link>

                    </Grid>
                    <Grid item sx={{ display: { md: 'flex', xs: 'none' } }}>
                        <img src={IcoPlus} alt="ícone de soma" style={{ width: '85%' }} />
                    </Grid>
                    <Grid item md={2} xs={5}>
                        <Link href="http://www.inf.puc-rio.br/" target="_blank" sx={{ textDecoration: 'none' }} >
                            <Grid container item sx={{ justifyContent: 'center' }}>
                                <img src={LogoDI} alt='logo DI' style={{ width: '100%' }} />
                            </Grid>
                        </Link>
                    </Grid>

                </Grid>



            </Box >
            <Grid container id="members" sx={{ pt: 10, pb: 10, }} >
                {/* background: 'linear-gradient(to bottom, #fff, #D2EEFF, #29ADFF)'  */}
                <Box sx={{ maxWidth: 'lg', width: '80%', margin: '0 auto' }}>
                    <Grid item xs={12}>
                        <Typography sx={{ fontWeight: "bold", color: "#003C6F", fontSize: { md: '40px', xs: '30px' }, paddingBottom: '15px', textAlign: "left" }}>
                            Membros
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid container item xs={12} justifyContent={"space-between"} display={"flex"} >
                            {membersData.MEMBERS.map((member, key) => (
                                <Grid item key={key} md={2.4} xs={6} sx={{ mt: 8 }}>
                                    <Link href={member.linkedin} target="_blank" sx={{ textDecorationLine: "none" }}>
                                        <img src={member.image} alt="avatar" />
                                        <Typography sx={{ color: '#2A2A2A', fontWeight: "medium" }}>{member.title}</Typography>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>


        </Grid >

    );
}

export default About;