import React from 'react';
import { AppBar, Toolbar, Container, Zoom, useScrollTrigger, useTheme, useMediaQuery, Grid, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as LinkScroll } from "react-scroll";
import SocialNetworks from '../utils/socialNetworks';
import LogoCEI from '../images/Logo-CEI.svg';
import LogoPUC from '../images/Logo-PUC-Rio.svg';
import IcoTopo from '../images/ico-topo.svg';
import DrawerComponent from "./drawer";

function AppBarLandingPage(props) {

    const theme = useTheme();

    const appBar = {
        boxShadow: "none",
        backgroundColor: "#003C6F",
        height: 'auto',
    };

    const toolbar = {
        padding: '0',
    }

    const MenuLink = styled(LinkScroll)({
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '550',
        '&:after': {
            content: "''",
            display: "block",
            width: "0",
            height: "2px",
            background: "#fff",
            transition: "width .3s",
        },
        '&:hover:after': {
            width: "100%",
        },
        '&:click': {
            borderBottom: '2px solid #fff', 
        },
        "&.active": {
            borderBottom: '2px solid #fff',
        }
    });
    const linkCss = {
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: '550',
        color: '#fff',
        textDecoration: "none",
        '&:after': {
            content: "''",
            display: "block",
            width: "0",
            height: "2px",
            background: "#fff",
            transition: "width .3s",
        },
        '&:hover:after': {
            width: "100%",
        },
        '&:click': {
            borderBottom: '2px solid #fff', 
        },
        "&.active": {
            borderBottom: '2px solid #fff',
        }
    };

    function ScrollTop(props) {
        const { children, window } = props;
        const trigger = useScrollTrigger({
            target: window ? window() : undefined,
            disableHysteresis: true,
            threshold: 100,
        });
        const handleClick = (event) => {
            const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

            if (anchor) {
                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        };
        return (
            <Zoom in={trigger}>
                <div onClick={handleClick} role="presentation" style={{ position: 'fixed', bottom: theme.spacing(2), right: theme.spacing(2), padding: '15px', zIndex: '100' }}>
                    {children}
                </div>
            </Zoom>
        );
    }


    const [active, setActive] = React.useState('home');

    const handleClick = (section) => (event) => {
        setActive(section);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    return (
        <Grid container>
            <AppBar style={appBar} >
                <Toolbar id="topo" style={toolbar}>
                    <Grid container sx={{ maxWidth: "lg", width: '80%', margin: "0 auto" }}>
                        <Grid container direction="row" xs={12} alignItems="center" justifyContent="space-between">
                            <Grid item md={4} xs={8} sx={{ textAlign: "justify" }}>
                                <LinkScroll smooth spy={true} to="home" style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    <img src={LogoCEI} alt='logo Conselho Empresarial para Inovação' style={{ width: "55%" }} />
                                </LinkScroll>
                            </Grid>
                            {isMobile ? (
                                <DrawerComponent />
                            ) : (
                                <Grid container item justifyContent="space-around" md={6} alignItems="center">
                                    <MenuLink smooth spy={true} to="home" offset={-120} onClick={handleClick('home')} > HOME </MenuLink>
                                    <MenuLink smooth spy={true} to="goals" offset={-100} onClick={handleClick('goals')} > OBJETIVOS </MenuLink>
                                    <MenuLink smooth spy={true} to="about" offset={-120} onClick={handleClick('about')} > SOBRE </MenuLink>
                                    <MenuLink smooth spy={true} to="members" offset={-90} onClick={handleClick('members')} > MEMBROS </MenuLink>
                                    <Link href="mailto: contato@cei.inf.puc-rio.br" sx={linkCss} onClick={handleClick('contact')} > CONTATO </Link>
                                </Grid>
                                // sx={{cursor: 'pointer', color: '#fff', textDecoration: 'none', fontSize: '20px', "@media(max-width: 1160px)": {fontSize: '16px'},fontWeight: '550',}}  style={{ borderBottom: active === 'contact' ? '4px solid #fff' : '', borderTop: active === 'faq' ? '4px solid #fff' : '', padding: active === 'faq' ? '4px 5px' : '' }}
                            )}

                            <Grid container item xs={2} spacing={2} sx={{ justifyContent: 'flex-end', alignItems: 'center', display: { md: 'inherit', xs: 'none' } }}>
                                <SocialNetworks color='#fff' size='150%' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ display: { md: 'flex', xs: 'none' }, justifyContent: "flex-end", height: '10vh' }}>
                        <Link href="https://www.puc-rio.br/index.html" target="_blank" sx={{ textDecoration: 'none' }} >
                            <img src={LogoPUC} alt='logo PUC-Rio' style={{ textAlign: "right", height: "95%" }} />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sx={{ backgroundColor: '#2A2A2A', width: '100%', height: '6px', zIndex: '100', position: 'absolute', bottom: '0px', opacity: '50%' }} >
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar id="back-to-top-anchor" />
            <Container>
                <ScrollTop {...props}>
                    <img src={IcoTopo} alt="ícone seta para subir ao topo do site" />
                </ScrollTop>
            </Container>
        </Grid>
    );

}

export default AppBarLandingPage;