import React from 'react';
import { Grid, Typography } from '@mui/material';
import IcoVisao from '../images/ico-visao.svg';
import IcoIdeias from '../images/ico-ideias.svg';
import IcoConexoes from '../images/ico-conexoes.svg';
import IcoVisaoBg from '../images/ico-visao-bg.svg';
import IcoIdeiasBg from '../images/ico-ideias-bg.svg';
import IcoConexoesBg from '../images/ico-conexoes-bg.svg';


const Goals = () => {

    return (
        <Grid container id="goals" >
            <Grid container item alignContent={'flex-end'} sx={{ mt: -15 }}>

                <Grid item md={2.4} sx={{ backgroundColor: "#2A2A2A", height: "45vh", display: { md: 'flex', xs: 'none' } }}>

                </Grid>
                <Grid item md={2.4} xs={12} sx={{ backgroundColor: "#2A2A2A", height: { md: "45vh", xs: "35vh" }, pr: 3, pl: 3, pb: 3, backgroundImage: `url(${IcoVisaoBg})`, backgroundRepeat: "no-repeat", backgroundPosition: "bottom right" }}>
                    <Grid item md={9} sx={{ margin: "auto", pt: 3, pb: 4 }}>
                        <img src={IcoVisao} alt="ícone de olho" style={{ paddingBottom: "5px" }} />
                        <Typography sx={{ color: "#fff", fontSize:  {md: "24px", xs: "20px"}, fontWeight: "bold", pb: 1 }}>
                            VISÃO
                        </Typography>
                        <Typography sx={{ color: "#dedede", fontSize: {md: "16px", xs: "13px"} }}>
                            Visão das condições e demandas do mercado e da sociedade, que necessitem de inovação de base tecnológica.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={2.4} xs={12} sx={{ backgroundColor: "#FFF7D6", height: { md: "45vh", xs: "35vh" }, pr: 3, pl: 3, pb: 3, backgroundImage: `url(${IcoIdeiasBg})`, backgroundRepeat: "no-repeat", backgroundPosition: "bottom right" }}>
                    <Grid item md={9} sx={{ margin: "auto", pt: 3, pb: 4 }}>
                        <img src={IcoIdeias} alt="ícone de lâmpada que remete a ideias" style={{ paddingBottom: "5px" }} />
                        <Typography sx={{ color: "#003C6F", fontSize:  {md: "24px", xs: "20px"}, fontWeight: "bold", pb: 1 }}>
                            IDEIAS
                        </Typography>
                        <Typography sx={{ color: "#2A2A2A", fontSize: {md: "16px", xs: "13px"} }}>
                            Um novo ponto de vista sobre as idéias dos alunos e professores.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={2.4} xs={12} sx={{ backgroundColor: "#29ADFF", height: { md: "45vh", xs: "35vh" }, pr: 3, pl: 3, pb: 3, backgroundImage: `url(${IcoConexoesBg})`, backgroundRepeat: "no-repeat", backgroundPosition: "bottom right" }}>
                    <Grid item md={12} sx={{ margin: "auto", pt: 3, pb: 3 }}>
                        <img src={IcoConexoes} alt="ícone de conexões" style={{ paddingBottom: "5px" }} />
                        <Typography sx={{ color: "#fff", fontSize: {md: "24px", xs: "20px"}, fontWeight: "bold", pb: 1 }}>
                            CONEXÕES
                        </Typography>
                        <Typography sx={{ color: "#fff", fontSize: {md: "16px", xs: "13px"} }}>
                            Promoção de conexões dos alunos e professores a pessoas do mercado e sociedade no Brasil e no resto do mundo para viabilizar as ideias, produtos e serviços gerados.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item md={2.4} sx={{ backgroundColor: "#29ADFF", height: "45vh", display: { md: 'flex', xs: 'none' } }}>

                </Grid>
                <Grid container xs={12} sx={{ backgroundColor: '#d6def2', width: '100%', height: '6px', zIndex: '10', bottom: '0px' }} >
                </Grid>
            </Grid>
        </Grid>

    )
}

export default Goals;