import React from 'react';
import { Grid, Typography, Link, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconEmail from '../images/ico_email';
import { Link as LinkScroll } from "react-scroll";
import SocialNetworks from '../utils/socialNetworks';
import LogoCEI from '../images/logo-CEI-footer.svg';
import LogoPUC from '../images/logo-PUC-Rio-footer.svg';
import LogoDI from '../images/logo-DI-footer.svg';


const MenuLink = styled(LinkScroll)({
    color: '#fff',
    cursor: "pointer",
    marginBottom: '10px',
    // fontSize: { md: '20px', xs: '16px' },
    fontSize: '16px',

    "&:hover": {
        textDecorationLine: "underline",
    },
});


const linkCss = {
    cursor: 'pointer',
    // fontSize: '18px',
    color: '#fff',
    textDecoration: "none",
    fontSize: '16px',
    "&:hover": {
        textDecorationLine: "underline",
    },
};

const Footer = () => {


    return (
        <Grid container item sx={{ backgroundColor: "#2A2A2A", padding: '35px', justifyContent: 'center' }} xl={12} lg={12} sm={12} xs={12}>
            <Box sx={{ maxWidth: 'lg', width: { sm: '80%', xs: '90%' }, margin: '0 auto' }}>
                <Grid container item xs={12}>
                    <Grid container item spacing={2} xs={12} sx={{ justifyContent: 'flex-end', display: { xs: "flex", md: "none" } }}>
                        <SocialNetworks color='#fff' size='150%' />
                    </Grid>
                    <Grid container item md={4} xs={8} >
                        <Grid item xs={12} sx={{ zIndex: '0', textAlign: "left" }}>
                            <MenuLink smooth spy={true} to="home" style={{ textDecoration: 'none', cursor: 'pointer', }}>
                                <img src={LogoCEI} alt='logo Conselho Empresarial para Inovação do DI' style={{ width: '80%' }} />
                            </MenuLink>
                        </Grid>



                        {/* <Grid container item xl={5} lg={5} sm={4} xs={12} sx={{ marginBottom: { md: '0px', xs: '30px' } }}> */}
                        <Grid container item xs={12} sx={{ alignItems: 'center' }}>
                            <Grid item md={5} xs={5.5} sx={{ textAlign: "left" }}>
                                <Link href="https://www.puc-rio.br/index.html" target="_blank" sx={{ textDecoration: 'none' }} >
                                    <img src={LogoPUC} alt='logo PUC' style={{ width: '80%' }} />
                                </Link>
                            </Grid>
                            <Grid item md={5} xs={5.5}>
                                <Link href="http://www.inf.puc-rio.br/" target="_blank" sx={{ textDecoration: 'none' }} >
                                    <img src={LogoDI} alt='logo DI' style={{ width: '80%' }} />
                                </Link>
                            </Grid>
                        </Grid>
                        {/* </Grid> */}
                        <Grid item xl={12} lg={12} md={3} sm={3} sx={{ display: { sm: 'contents', xs: 'none' } }}>

                            <IconEmail sx={{ paddingRight: '5px', fontSize: '19px' }} />
                            <Link href="mailto: contato@cei.inf.puc-rio.br" sx={{
                                textDecoration: 'none', "&:hover": {
                                    textDecorationLine: "underline", color: '#fff'
                                },
                            }}>
                                <Typography sx={{ color: "#FFFFFF", fontSize: '15px' }}>
                                    contato@cei.inf.puc-rio.br
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid container item md={4} xs={12}>
                        <Grid container item xs={12} sx={{ display: 'grid', justifyContent: {md: "center", xs: "flex-start"} }}>
                            <Grid item sx={{ marginBottom: { md: '50px', xs: '45px' }, marginTop: { md: '0px', xs: '20px' } }}>
                                <Typography sx={{ color: "#FFFFFF", fontSize: { sm: '20px', xs: '16px' }, borderBottom: '3px solid #fff', width: '55%' }}>
                                    Links
                                </Typography>
                            </Grid>
                            <Grid container item rowSpacing={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', zIndex: '1', textAlign: 'left' }}>
                                <MenuLink smooth spy={true} to="home" offset={-120}> HOME </MenuLink>
                                <MenuLink smooth spy={true} to="goals" offset={-120}> OBJETIVOS </MenuLink>
                                <MenuLink smooth spy={true} to="about" offset={-120}> SOBRE </MenuLink>
                                <MenuLink smooth spy={true} to="members" offset={-120}> MEMBROS </MenuLink>
                                <Link href="mailto: contato@cei.inf.puc-rio.br" sx={linkCss}> CONTATO </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} md={4} sx={{ justifyContent: 'flex-end', display: { xs: "none", md: "flex" } }}>
                        <SocialNetworks color='#fff' size='150%' />
                    </Grid>
                    <Grid container item md={12} sx={{ justifyContent: 'center', marginTop:  '60px', display: {md: "block", xs: "none"}  }}>
                        <Typography sx={{ color: "#FFFFFF", fontSize: { sm: '13px', xs: '10px' }, textAlign: 'center' }}>
                            CEI: Conselho Empresarial para Inovação. Copyright 2022 - Todos os direitos reservados.
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} sx={{ justifyContent: 'center', marginTop: '40px', display: {md: "none", xs: "block"} }}>
                        <Typography sx={{ color: "#FFFFFF", fontSize: { sm: '13px', xs: '10px' }, textAlign: 'center' }}>
                            CEI: Conselho Empresarial para Inovação. Copyright 2022.
                        </Typography>
                        <Typography sx={{ color: "#FFFFFF", fontSize: { sm: '13px', xs: '10px' }, textAlign: 'center' }}>
                            Todos os direitos reservados.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

export default Footer;