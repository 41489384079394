import React from 'react';
import { Grid } from '@mui/material';
import Header from '../template/header';
import VideoSection from '../template/videoSection';
import About from '../template/about';
import Goals from '../template/goals';
import Footer from '../template/footer';


function LandingPage(props) {
    
    return( 
        <Grid container sx={{overflow: "hidden", }}>
            <Header />
            <VideoSection />
            <Goals />
            <About />
            <Footer />
        </Grid>
    );
}

export default LandingPage;