import React, { useState } from "react";
import { Drawer, Divider, Grid, Link } from '@mui/material';
import { Link as LinkScroll } from "react-scroll";
import { styled } from '@mui/material/styles';
import SocialNetworks from '../utils/socialNetworks';


function DrawerComponent() {

    const [openDrawer, setOpenDrawer] = useState(false);

    const MenuLink = styled(LinkScroll)({
        cursor: "pointer",
        color: '#003C6F',
        padding: '15px',
        fontWeight: '550'
    });
    
    const linkCss = {
        cursor: "pointer",
        color: '#003C6F',
        padding: '15px',
        fontWeight: '550',
        textDecoration: "none",
    };


    return (
        <>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor={"right"} BackdropProps={{ invisible: true }} PaperProps={{ style: { height: 'auto', top: "56px", width: '60vw' } }}>

                <MenuLink activeClass="active" onClick={() => setOpenDrawer(false)} smooth spy={true} to="home" offset={-80}> HOME </MenuLink>
                <Divider />
                <MenuLink activeClass="active" onClick={() => setOpenDrawer(false)} smooth spy={true} to="goals" offset={-150}> OBJETIVOS </MenuLink>
                <Divider />
                <MenuLink activeClass="active" onClick={() => setOpenDrawer(false)} smooth spy={true} to="about" offset={-80}> SOBRE </MenuLink>
                <Divider />
                <MenuLink activeClass="active" onClick={() => setOpenDrawer(false)} smooth spy={true} to="members" offset={-50}> MEMBROS </MenuLink>
                <Divider />
                <Link href="mailto: contato@cei.inf.puc-rio.br" sx={linkCss} onClick={() => setOpenDrawer(false)}> CONTATO </Link>
                <Divider />

                <Grid container item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: '30px', paddingBottom: '30px' }}>
                    <SocialNetworks color='#003C6F' size='200%' />
                </Grid>
            </Drawer>
            <Grid container item sx={{width: "32px", height: "22px", display: "flex", justifyContent: "space-around", flexFlow: "column nowrap", zIndex: '10', }} onClick={() => setOpenDrawer(!openDrawer)}>
                <Grid item sx={{width: "28px", height: "2px", borderRadius: "10px", backgroundColor: "#FFF", transformOrigin: "top left", transition: "all 0.5s ease-in", transform: openDrawer ? "translateX(101%) translateY(-3px)  rotate(135deg)" : "rotate(0)"}}></Grid>
                <Grid item sx={{width: "28px", height: "2px", borderRadius: "10px", backgroundColor: "#FFF", transformOrigin: "top left", transition: "all 0.5s ease-in",  transform: openDrawer ? "translateX(100%) rotate(90deg)" : "translateX(0)",opacity: openDrawer ? 0 : 1}}></Grid>
                <Grid item sx={{width: "28px", height: "2px", borderRadius: "10px", backgroundColor: "#FFF", transformOrigin: "bottom right", transition: "all 0.5s ease-in", transform: openDrawer ? "rotate(45deg)" : "rotate(0)"}}></Grid>
            </Grid>
        </>
    )
}

export default DrawerComponent;