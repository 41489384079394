import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';


function Linkedin(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...props}>
            <path d="M20,40A20.005,20.005,0,0,1,12.215,1.572a20.005,20.005,0,0,1,15.57,36.857A19.874,19.874,0,0,1,20,40Zm3.65-20.181c1.048,0,2.3.534,2.3,3.079v7.286h4.532V22.375a6.619,6.619,0,0,0-1.491-4.633,4.909,4.909,0,0,0-3.728-1.5,4.683,4.683,0,0,0-2.719.777,5.079,5.079,0,0,0-1.366,1.473V16.565H16.643c.059,1.264,0,13.494,0,13.618h4.533V22.578a3,3,0,0,1,.149-1.1,2.822,2.822,0,0,1,.816-1.132A2.331,2.331,0,0,1,23.651,19.819ZM9.6,16.565V30.183h4.532V16.565ZM11.9,10a2.36,2.36,0,1,0-.059,4.706h.029a2.643,2.643,0,0,0,1.875-.687,2.279,2.279,0,0,0,.69-1.667A2.351,2.351,0,0,0,11.9,10Z" fill="#fff"/>
        </SvgIcon>
    );
}

export default Linkedin;