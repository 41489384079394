import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';


function IconEmail(props) {
    return (
        <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" {...props}>
            <path d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z" transform="translate(-2 -4)" fill="#fff"/>
        </SvgIcon>
    );
}

export default IconEmail;