import alberto from '../images/members/alberto.png';
import arnon from '../images/members/arnon.png';
import artur from '../images/members/artur.png';
import bertrand from '../images/members/bertrand.png';
import carlos from '../images/members/carlos.png';
import daniela from '../images/members/daniela.png';
import denis from '../images/members/denis.png';
import eduardo from '../images/members/eduardo.png';
import federico from '../images/members/federico.png';
import marcio from '../images/members/marcio.png';
import pedro from '../images/members/pedro.png';
import pedro_zinner from '../images/members/pedro_zinner.png';
import renata from '../images/members/renata.png';
import simone from '../images/members/simone.png';
import tulio from '../images/members/tulio.png';


export const MEMBERS = [
    {
        image: alberto,
        title:"Alberto Leite",
        linkedin: "https://www.linkedin.com/in/albertoleite/",
    },
    {
        image: arnon,
        title:"Arnon de Mello",
        linkedin: "https://www.linkedin.com/in/arnondemello/",
    },
    {
        image: artur,
        title:"Artur Wichman",
        linkedin: "https://www.linkedin.com/in/artur-wichmann-2279a234/",
    },
    {
        image: bertrand,
        title:"Bertrand Chaverot",
        linkedin: "https://www.linkedin.com/in/bertrand-chaverot-2a2136/",
    },
    {
        image: carlos,
        title:"Carlos Simas",
        linkedin: "https://www.linkedin.com/in/carlos-simas-790270/",
    },
    {
        image: daniela,
        title:"Daniela Binatti",
        linkedin: "https://www.linkedin.com/in/danielabinatti/",
    },
    {
        image: denis,
        title:"Denis Jungerman",
        linkedin: "https://www.linkedin.com/in/denis-jungerman-175200/",
    },
    {
        image: eduardo,
        title:"Eduardo Alvarenga",
        linkedin: "https://www.linkedin.com/in/eduardo-alvarenga-60798780/",
    },
    {
        image: federico,
        title:"Federico Grosso",
        linkedin: "https://www.linkedin.com/in/federicogrosso/",
    },
    {
        image: marcio,
        title:"Marcio Aguiar",
        linkedin: "https://www.linkedin.com/in/marcioaguiar/",
    },
    {
        image: pedro,
        title:"Pedro Thompson",
        linkedin: "https://www.linkedin.com/in/pedro-thompson-06765090/",
    },
    {
        image: pedro_zinner,
        title:"Pedro Zinner",
        linkedin: "https://www.linkedin.com/in/pedro-zinner-a8972/",
    },
    {
        image: renata,
        title:"Renata Afonso",
        linkedin: "https://www.linkedin.com/in/renata-afonso/",
    },
    {
        image: simone,
        title:"Simone Caggiano",
        linkedin: "https://www.linkedin.com/in/simone-caggiano/",
    },
    {
        image: tulio,
        title:"Tulio Ribeiro",
        linkedin: "https://www.linkedin.com/in/tulio-ribeiro-0aa253/",
    },
];
