import React, { useState } from 'react'
import { IconButton, Grid, useMediaQuery, useTheme, Typography, Link, Button } from '@mui/material';
import ReactPlayer from 'react-player'
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PucImage from '../images/foto-puc.png';


const VideoSection = (props) => {


    const bannerButton = {
        border: '1px solid',
        borderColor: '#fff',
        backgroundColor: '#eee',
        color: '#000',
        textTransform: 'none',
        marginTop: { md: '25px', xs: '45px' },
        width: 'auto',
        height: 'auto',
        fontSize: { xl: "26px", lg: "24px", md: '22px', sm: '20px', xs: '15px' },
        padding: { md: '5px 35px', xs: '3px 20px' },
        whiteSpace: 'nowrap',
        '& .css-1d6wzja-MuiButton-startIcon>*:nth-of-type(1)': {
            fontSize: { md: '28px', xs: '20px' }
        },
        '&:hover': {
            backgroundColor: '#2A2A2A',
            color: '#fff',
            borderColor: '#fff',
        },
    };



    const [muted, setMuted] = useState(1);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    return (
        <Grid container id="home" >
            {isMobile ? (
                <>
                    <img src={PucImage} alt="foto do prédio da PUC-Rio" style={{ width: "100vw", height: "100vh" }} />
                </>) : (
                <>
                    <ReactPlayer
                        // url={window.location.origin + '/video/futuro-estudante-de-informática-puc-rio.mp4'}
                        url="https://www.youtube.com/watch?v=-r72mJDvHio"
                        width="100vw"
                        height="95vh"
                        playing={true}
                        loop={true}
                        playsinline={true}
                        controls={false}
                        volume={muted ? 0 : 1}

                    // muted={true}
                    />
                    <IconButton onClick={() => setMuted(muted => !muted)} style={{ zIndex: '150', position: "absolute", top: '20vh', left: '15vw' }}>
                        {
                            muted ? <VolumeOffIcon sx={{ color: "#fff", fontSize: '35px', }} /> : <VolumeUpIcon sx={{ color: "#fff", fontSize: '35px', }} />
                        }
                    </IconButton>
                </>
            )}
            <Grid container item md={7} xs={9} sx={{ zIndex: '150', position: "absolute", top: '25vh', left: { md: "20vw", xs: "10vw" }, margin: "auto", textAlign: "justify" }}>
                <Grid item xs={12} justifyContent={"center"}>
                    <Typography sx={{ textAlign: "left", color: "#fff", fontSize: { md: "45px", xs: "32px" }, textShadow: "2px 2px #000" }}>
                        Agregar Conhecimento e Experiência
                    </Typography>
                    <Typography sx={{ textAlign: "left", color: "#fff", fontSize: { md: "26px", xs: "20px" }, textShadow: "2px 2px #000" }}>
                        Orientar e incentivar a inovação desenvolvida no Departamento de Informática e conectar os discentes e docentes às demandas da sociedade.
                    </Typography>
                    <Link href="mailto: contato@cei.inf.puc-rio.br" target="_blank" sx={{ textDecoration: 'none' }}>
                        <Button variant="outlined" sx={bannerButton}>
                            CONTATO
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </Grid>

    )
};

export default VideoSection;